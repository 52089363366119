










































































import { Component, Vue, Prop } from 'vue-property-decorator';

import QRCodeCoreModel from '@/modules/qr-codes/models/QRCodeCoreModel';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

import DetailHorizontal from '@/shared/resources/components/details/horizontal/DetailHorizontal.vue';
import DetailDivider from '@/shared/resources/components/details/DetailDivider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Popup from '@/shared/lib/support/popups/Popup';
import Alert from '@/shared/resources/components/Alert.vue';
import ApiORMQueryBuilder from '@/core/bridge/orm/api/ApiORMQueryBuilder';

@Component({
  components: {
    Alert,
    Icon,
    Button,
    DetailDivider,
    DetailHorizontal,
  },
})
export default class QRReaderConfirm extends Vue {
  /**
   * Props
   */
  @Prop() private query!: ApiORMQueryBuilder;
  @Prop() private qrCode!: QRCodeCoreModel | null;

  /**
   * Data
   */
  private loading: boolean = false;
  private success: boolean = false;

  /**
   * Display getters
   */
  private get displayIcon(): boolean {
    return !!this.qrCode?.featuredState;
  }

  private get displayConfirmButton(): boolean {
    return !!this.qrCode?.isActive;
  }

  private get displayCloseButton(): boolean {
    return !this.qrCode?.isActive;
  }

  private get displayInvalidAlert(): boolean {
    return !this.qrCode?.isValid;
  }

  private get displayUsedAlert(): boolean {
    return !!this.qrCode?.isUsed;
  }

  private get displayExpiredAlert(): boolean {
    return !!this.qrCode?.isExpired && !this.qrCode?.isUsed;
  }

  /**
   * Class names
   */
  private get classNames(): any {
    return {
      'qr-featured': !!this.qrCode?.featuredState,
    };
  }

  /**
   * Getters
   */
  private get title(): string | null {
    if (!this.qrCode) {
      return null;
    }

    return this.qrCode.featuredState || this.qrCode.title;
  }

  private get processedData(): IndexableInterface | null {
    if (!this.qrCode) {
      return null;
    }

    return this.qrCode.getProcessedContent();
  }

  private get buttonColor(): string {
    return this.success ? 'success' : 'primary';
  }

  private async makeQRCodeUserRequest() {
    this.loading = true;

    try {
      await this.query
        .param('id', this.qrCode!.id)
        .update();

      this.success = true;

      setTimeout(() => {
        this.$emit('hide');
        this.success = false;
      }, 2000);
    } catch (e) {
      Popup.error('Wystąpił błąd.');
    }

    this.loading = false;
  }

  /**
   * Handlers
   */
  private onConfirmButtonClick() {
    if (this.loading || this.success) {
      return;
    }

    this.makeQRCodeUserRequest();
  }

  private onCloseButtonClick() {
    this.$emit('hide');
  }
}
